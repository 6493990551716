<template>
    <div class="vx-col w-full mb-base">
        <div class="vx-row mb-12">
            <div v-bind:class="[
                detail ? detailShow + 'md:w-1/3 w-full mb-base' : '',
                detailHide,
            ]">
                <vs-button v-on:click="submit()" color="primary" icon-pack="feather" icon="icon-inbox"
                    v-show="this.tab == 0">Submit</vs-button>
                <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
                    :total="table.total" @search="handleSearch" @change-page="handleChangePage">
                    <template slot="header">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div
                                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total
                                    }}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>
                                <vs-dropdown-item v-for="item in table.limits" :key="item"
                                    @click="handleChangelength(item)">
                                    <span>{{ item }}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </template>
                    <template slot="thead">
                        <vs-th width="10%"></vs-th>
                        <vs-th v-show="this.tab == 0">
                            <vs-checkbox v-on:click="approveAll()" v-model="statusApproveAll" color="primary">Approve
                                All</vs-checkbox>
                        </vs-th>
                        <vs-th v-show="this.tab == 0">
                            <vs-checkbox v-on:click="rejectAll()" v-model="statusRejectAll" color="warning">Reject
                                All</vs-checkbox>
                        </vs-th>
                        <vs-th sort-key="code">Work Order ID </vs-th>
                        <vs-th sort-key="warehouse_name">Warehouse</vs-th>
                        <vs-th sort-key="request_date">Request Date</vs-th>
                        <vs-th sort-key="execute_date">Execute Date</vs-th>
                        <vs-th sort-key="item_bom_code">BoM ID </vs-th>
                        <vs-th sort-key="name">Parent SKU Data </vs-th>
                        <vs-th sort-key="name">Child SKU Data </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :class="data[indextr].class">
                            <vs-td class="align-top">
                                <div class="vx-input-group flex">
                                    <vx-tooltip text="Show">
                                        <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                            title="Detail" style="margin-right: 5px" @click="handleShow(tr.id)" />
                                    </vx-tooltip>
                                    <vx-tooltip text="Edit">
                                        <vs-button v-show="isWHManager && tab == 0" size="small" color="warning"
                                            icon-pack="feather" icon="icon-edit" title="Edit" style="margin-right: 5px"
                                            @click="handleEdit(tr.id)" />
                                    </vx-tooltip>
                                </div>
                            </vs-td>
                            <vs-td v-show="tab == 0" class="align-top">
                                <vs-checkbox v-on:click="approve(indextr, data[indextr].id)"
                                    :checked="id_approve.includes(tr.id)" color="primary"></vs-checkbox>
                            </vs-td>
                            <vs-td v-show="tab == 0" class="align-top">
                                <vs-checkbox v-on:click="reject(indextr, data[indextr].id)"
                                    :checked="id_reject.includes(tr.id)" color="warning"></vs-checkbox>
                            </vs-td>
                            <vs-td class="align-top" :data="data[indextr].code">{{ data[indextr].code }}</vs-td>
                            <vs-td class="align-top" :data="data[indextr].warehouse_name">{{
                                data[indextr].warehouse_name ?
                                    data[indextr].warehouse_name : "" }}</vs-td>
                            <vs-td class="align-top" :data="data[indextr].request_date">{{ data[indextr].request_date ?
                                formatDate(data[indextr].request_date) : "" }}</vs-td>
                            <vs-td class="align-top" :data="data[indextr].execution_date">{{
                                data[indextr].execution_date ?
                                    formatDate(data[indextr].execution_date) : "" }}</vs-td>
                            <vs-td class="align-top" :data="data[indextr].item_bom_code">{{ data[indextr].item_bom_code
                                ?
                                data[indextr].item_bom_code : "" }}</vs-td>
                            <vs-td class="align-top" :data="data[indextr].name">{{ data[indextr].item_name ?
                                data[indextr].item_name : ""
                                }}</vs-td>
                            <vs-td>
                                <vs-tr v-for="(trx, indextrx) in tr.child_data" :data="trx" :key="indextrx">
                                    <vs-td>{{ trx.item_name }}</vs-td>
                                </vs-tr>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
    props: ['tab'],
    data() {
        return {
            data: [],
            detailShow: "core vx-col ",
            detailHide: "core vx-col md:w-1/1 w-full mb-base ",
            detail: false,
            table: {
                data: [],
                length: 5,
                page: 1,
                search: "",
                order: "id",
                sort: "asc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [5, 10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            },
            status: 2,
            statusApproveAll: false,
            statusRejectAll: false,
            id_approve: [],
            id_reject: [],
            index1: 0,
            isWHManager: false,
        }
    },
    mounted() {
        this.checkRole()
    },
    methods: {
        submit() {
            if (this.id_approve.length == 0 && this.id_reject.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Select data first.",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.loading();
                let param = {
                    crl_id_approve: this.id_approve,
                    crl_id_reject: this.id_reject,
                };
                this.$http
                    .post("api/wms/v1/work-order/approval", param)
                    .then((resp) => {
                        this.$vs.loading.close();
                        if (resp.code == 200) {
                            this.handleClose();
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.detail = false;
        },
        handleShow(id) {
            this.$router.push({
                name: "work-order-ho-show",
                params: { id },
            });
        },
        handleEdit(id) {
            this.$router.push({
                name: "work-order-ho-edit",
                params: { id: id },
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        approveAll() {
            this.statusRejectAll = false;
            let _this = this;
            this.table.data.forEach(function (element) {
                element.status_checked_reject = false;
                element.status_checked_approve = true;

                if (!_this.id_approve.includes(element.id)) {
                    _this.id_approve.push(element.id);
                } else {
                    var index2 = _this.id_approve.indexOf(element.id);
                    if (index2 !== -1) {
                        _this.id_approve.splice(index2, 1);
                    }
                }
            });
            this.id_reject = [];
        },
        rejectAll() {
            this.statusApproveAll = false;
            let _this = this;
            this.table.data.forEach(function (element) {
                element.status_checked_reject = true;
                element.status_checked_approve = false;

                if (!_this.id_reject.includes(element.id)) {
                    _this.id_reject.push(element.id);
                } else {
                    var index2 = _this.id_reject.indexOf(element.id);
                    if (index2 !== -1) {
                        _this.id_reject.splice(index2, 1);
                    }
                }
            });
            this.id_approve = [];
        },
        approve(index, id_item) {
            console.log("approve");
            if (!this.id_approve.includes(id_item)) {
                this.id_approve.push(id_item);
            } else {
                var index2 = this.id_approve.indexOf(id_item);
                if (index2 !== -1) {
                    this.id_approve.splice(index2, 1);
                }
            }
            var index1 = this.id_reject.indexOf(id_item);
            if (index1 !== -1) {
                this.id_reject.splice(index1, 1);
            }
        },
        reject(index, id_item) {
            console.log("reject");
            if (!this.id_reject.includes(id_item)) {
                this.id_reject.push(id_item);
            } else {
                var index2 = this.id_reject.indexOf(id_item);
                if (index2 !== -1) {
                    this.id_reject.splice(index2, 1);
                }
            }

            var index1 = this.id_approve.indexOf(id_item);
            if (index1 !== -1) {
                this.id_approve.splice(index1, 1);
            }
        },
        getData() {
            const tab = this.tab
            let status = this.status
            const approvedStatus = 5
            const rejectedStatus = 6

            switch (tab) {
                case 1:
                    status = approvedStatus
                    break;
                case 2:
                    status = rejectedStatus
                    break;
                default:
                    status = this.status
                    break;
            }

            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/approval-table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        status,
                        is_approval: 1,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_records;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_seardh;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.data = resp.data.records;

                        this.setStartEnd();
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        checkRole() {
            this.$vs.loading();
            this.$http.get("api/wms/v1/work-order/check-roles")
                .then((res) => {
                    const { data } = res
                    this.isWHManager = data.whManager
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.$vs.loading.close();
                })
        }
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD");
        },
    }
}
</script>
<style scoped>
.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 800px;
    padding: 5px;
}

.highlight>td {
    background-color: #d0cdf0;
}

.danger>td {
    background-color: #f77f7f;
}

.con-vs-checkbox {
    margin-left: auto;
    margin-right: auto;
}
</style>